import React from "react";

import ContactForm from "../../components/SnowBirdsContactForm";
import Section from "../../components/Section";
import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";

import styles from "./index.module.scss";

const SnowbirdsContactPage = () => {
  return (
    <Layout>
      <Background>
        <Hero>
          <MotifColumn>
            <h1>Lets get you started</h1>
            <p>
              Our team is ready to help you with all of your foreign exchange
              and global payments needs, all you need to do is enter your
              information below. We look forward to partnering with you to make
              the process quick, easy, and completely stress-free. Let’s do
              this!
            </p>
          </MotifColumn>
        </Hero>
        <Section className={styles.contactSection}>
          <ContactForm />
        </Section>
      </Background>
    </Layout>
  );
};

export default SnowbirdsContactPage;
