import React from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "../Button";
import { injectIntl, IntlContextConsumer } from "gatsby-plugin-intl";

import styles from "./index.module.scss";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class SnowbirdsContactForm extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Col xs={12} lg={6}>
          <div className={styles.contactFormWrapper}>
            <Formik
              validationSchema={yup.object().shape({
                firstName: yup.string().required(
                  intl.formatMessage({
                    id: "contact-form.form.first-name.errors.required",
                  })
                ),
                lastName: yup.string().required(
                  intl.formatMessage({
                    id: "contact-form.form.last-name.errors.required",
                  })
                ),
                email: yup
                  .string()
                  .email(
                    intl.formatMessage({
                      id: "contact-form.form.email.errors.email",
                    })
                  )
                  .required(
                    intl.formatMessage({
                      id: "contact-form.form.email.errors.required",
                    })
                  ),
                telephone: yup.string(),
                question: yup.string().required(
                  intl.formatMessage({
                    id: "contact-form.form.question.errors.required",
                  })
                ),
              })}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                telephone: "",
                question: "",
              }}
              onSubmit={(data) => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({
                    "form-name": "snowbirds-contact",
                    ...data,
                  }),
                })
                .then(() => {
                  alert("Success!");
                })
                .catch((error) => alert(error));
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <IntlContextConsumer>
                  {({ languages, language: currentLocale }) => (
                <Form
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  name="snowbirds-contact"
                  method="POST"
                  noValidate
                  action={`/${currentLocale}/contact-success`}
                >
                  <input type="hidden" name="bot-field" onChange={handleChange} />
                  <input type="hidden" name="form-name" value="snowbirds-contact" />
                  <Form.Group controlId="formFirstName">
                    <Form.Label>
                      {intl.formatMessage({
                        id: "contact-form.form.first-name.label",
                      })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      isInvalid={touched.firstName && errors.firstName}
                      className={styles.contactFormInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formLastName">
                    <Form.Label>
                      {intl.formatMessage({
                        id: "contact-form.form.last-name.label",
                      })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      isInvalid={touched.lastName && errors.lastName}
                      className={styles.contactFormInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formEmail">
                    <Form.Label>
                      {intl.formatMessage({
                        id: "contact-form.form.email.label",
                      })}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={touched.email && errors.email}
                      className={styles.contactFormInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formTelephone">
                    <Form.Label>
                      {intl.formatMessage({
                        id: "contact-form.form.telephone.label",
                      })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="telephone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telephone}
                      isInvalid={touched.telephone && errors.telephone}
                      className={styles.contactFormInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.telephone}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formQuestion">
                    <Form.Label>
                      {intl.formatMessage({
                        id: "contact-form.form.question.label",
                      })}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="question"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.question}
                      isInvalid={touched.question && errors.question}
                      className={styles.contactFormTextarea}
                      style={{ height: 200 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.question}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    className={styles.contactFormSubmitButton}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({
                      id: "contact-form.form.submit.text",
                    })}
                  </Button>
                </Form>
                )}
                </IntlContextConsumer>
              )}
            </Formik>
          </div>
        </Col>
      </>
    );
  }
}

export default injectIntl(SnowbirdsContactForm);
